$color-pink: #FF6679;
$color-yellow: #FFE66D;
$color-teal: #8AE1D7;
$color-blue: #556FCD;
$color-deep-blue: #0A1128;

$color-text: #162149;

$color-input-bg: #F8FAFF;
$color-input-border: #D2D6E5;
$color-shadow: rgba(0,0,0,.1);

$color-white: #ffffff;

$max-width: 1336px;
$breakpoint-xs: 375px;
$breakpoint-sm: 568px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;

@mixin xs {
  @media (min-width: $breakpoint-xs) {
    @content;
  }
}

@mixin sm {
  @media (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin md {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin lg {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin xl {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.l-about {
  background-color: #0A1128;
  padding: 25px 20px;

  @include md {
    padding: 60px 20px;
  };

  > .pure-g {
    max-width: 1100px;
    margin: 0 auto;
  }

  &__text p {
    color: $color-white;

    @include md {
      padding-left: 30px;

    };
  }
}

.l-join {
  padding: 25px 20px;

  @include md {
    padding: 60px 20px;
  };

  > .pure-g {
    max-width: 1100px;
    margin: 0 auto;
  }

  &__text p {
    margin-bottom: 25px;

    @include md {
      max-width: 290px;
    };
  }
}

.l-form {
  max-width: 700px;
  margin: 20px auto;

  &__input {
    @include sm {
      padding: 0 15px;
      box-sizing: border-box;
    };
  }

  &__spinner {
    display: none;
  }

  &__success {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 0 15px;
    text-align: center;

    h2 {
      margin-top: 0;
    }

    img {
      width: 150px;
    }

    p {
      max-width: 420px;
      margin: 20px auto;
    }

    &--active {
      display: block;
      animation: fadeIn .75s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
  }

  @include md {
    .c-button {
      width: 200px;
      margin: 15px;
    }
  };
}

.l-dribbble, .l-spotify, .l-youtube {
  max-width: 1100px;
  margin: 20px auto;
  padding: 25px 20px;

  @include md {
    h1 {
      max-width: 290px;
    }

    .t-body {
      max-width: 290px;
      margin-right: 50px;
    }
  };
}

.l-footer {
  background-color: $color-deep-blue;
  color: $color-white;

  > div {
    max-width: 1100px;
    margin: 20px auto 0;
    padding: 50px 20px;
  }

  .c-logo {
    display: block;
    margin: 18px auto;

    @include sm {
      margin: 18px 0;
    };
  }

  .c-social-icons {
    margin: 15px 0;
    text-align: right;
    float: none;

    a {
      color: $color-white;
      display: block;
      margin: 15px 0;
      text-align: center;

      @include sm {
        display: inline-block;
        text-align: right;
        margin: 15px 10px;
      };

      svg {
        position: relative;
        top: 3px;
      }
    }
  }

  &__cta {
    text-align: center;
    margin: 25px 0;

    @include sm {
      text-align: right;
      margin: 0;
    };

    p, a {
      display: inline-block;
      color: $color-white;
    }

    a {
      width: 200px;
      text-align: center;
      background: 0;
      margin: 0 0 0 8px;
    }
  }
}
